<template>
  <Evaluation
    :id="attemptId"
    :key="attemptId"
    :title="$t('app.attempts')"
    :type="evaluationTypes.QUIZ"
    hide-feedback
  />
</template>

<script>
// Components
import Evaluation from "@/views/learning/evaluations/Evaluation";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  components: {
    Evaluation
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    attemptId: {
      type: [Number, String],
      required: true
    }
  },
  setup() {
    return {
      evaluationTypes
    };
  }
};
</script>
